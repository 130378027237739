import MessageMap from "../i18n.d";

const es419: MessageMap = {
  UpdateButton: {
    TEXT: "Actualizar",
  },
  AccountOverview: {
    TITLE: "Descripción general del PDV",
  },
  ClubB: {
    TITLE: "Club B",
  },
  PhoneNumbers: {
    TITLE: "Números de teléfono",
    CALLED_TAG: "Llamado",
    COPIED_TAG: "Copiado",
    COPY_TOOLTIP: "Copiar",
    COPIED_TOOLTIP: "Copiado",
    NUMBER_RATE: {
      GOOD: "Buen numero",
      BAD: "Mal numero",
      NEUTRAL: "Numero neutro",
    },
    TOOLTIP: {
      INACTIVE: "Inactivo",
      NO_ANSWER: "Sin respuesta",
      SUCCESSFUL_CALL: "llamada exitosa",
      CLICK_CALL: "Haga clic para llamar",
      COPY_NUMBER: "Copiar número",
      EDIT_NUMBER: "Editar número",
    },
    PHONE_TYPE: {
      INPUTTED_PHONE: "Añadido por el agente",
      BEES_PHONE: "Teléfono BEES",
      ERP_PHONE: "Teléfono ERP",
    },
    BUTTON_ACTIVATE: "Activar",
    BUTTON_ADD_PHONE: "Agregar un nuevo teléfono",
    Modal: {
      DEACTIVATE_TITLE: "Desactivar número de teléfono?",
      ACTIVATE_TITLE: "Activar número de teléfono?",
      Button: {
        ACTIVATE: "Activar",
        DEACTIVATE: "Desactivar",
        CANCEL: "Cancelar",
      },
      ModalMessage: {
        ACTIVATE_NUMBER: "Podrá hacer llamadas al número",
        INACTIVATE_NUMBER: "No podrá hacer llamadas al número",
        INACTIVATE_NOTE: "si está inactivo.",
      },
    },
    AddEditPhoneModal: {
      ADD_TITLE: "Agregar nuevo teléfono",
      EDIT_TITLE: "Editar teléfono",
      FIELD_PHONE_NUMBER: "Número de teléfono",
      FIELD_RESPONSIBLE: "Responsable",
      SUB_TITLE: "Llene el formulario para agregar un nuevo número.",
      PHONE_HINT_TEXT:
        "Incluya el código de país, el código de área y el número de teléfono.",
      NAME_HINT_TEXT: "Persona que contesta este número.",
      REQUIRED_FIELD: "Este campo es obligatorio.",
      BUTTON_SAVE: "Guardar",
      BUTTON_CANCEL: "Cancelar",
      CREATE_SUCCESS_MESSAGE: "El número de teléfono se guardó correctamente.",
      CREATE_ERROR_MESSAGE: "No se puede guardar el número de teléfono.",
      EDIT_SUCCESS_MESSAGE: "El número de teléfono ha sido editado.",
      EDIT_ERROR_MESSAGE: "No se puede editar el número de teléfono.",
    },
    ToastMessage: {
      Success: {
        ACTIVATION: "El número de teléfono ${} ha sido activado.",
        INACTIVATION: "El número de teléfono ${} ha sido desactivado.",
        CLASSIFICATION: "El teléfono ha sido clasificado.",
      },
      Error: {
        ACTIVATION: "No se puede activar el número de teléfono ${}.",
        INACTIVATION: "No se puede desactivar el número de teléfono ${}.",
        CLASSIFICATION: "No se puede clasificar el número de teléfono.",
      },
    },
    Error: {
      MESSAGE:
        "No se puede cargar la información de los números de teléfono del PDV.",
      ACTION: "Intentar otra vez",
    },
  },
  Tasks: {
    TITLE: "Tareas de llamadas",
    UNABLE_TO_LOAD: "No se puede cargar la información de las tareas.",
    TRY_AGAIN: "Intentar de nuevo",
    EMPTY: "No hay tareas asignadas a este POC.",
    GO_TO_TASKS: "Ir a tareas",
    Global: {
      Title: {
        TOPLINE: "Tarea de topline",
        MARKETPLACE: "Tarea de marketplace",
      },
      Description: {
        TOPLINE: "Recomendar productos basados en el pedido sugerido.",
        MARKETPLACE: "Fomentar la compra de productos del marketplace.",
      },
    },
    Local: {
      Description: {
        DEFAULT: "Tarea personalizada creada por los equipos de operaciones.",
      },
    },
  },
  PaymentMethod: {
    Methods: {
      CREDIT: "Línea de compra",
      CASH: "Efectivo",
      BANK_SLIP: "Plazos de pago",
      BANK_SLIP_INSTALLMENT: "Pago aplazado",
      CHECK: "Cheque",
      CREDIT_CARD_POS: "Tarjeta de crédito (pago contra entrega)",
      CREDIT_CARD_ONLINE: "Tarjeta de crédito online",
      PIX_AT_DELIVERY: "PIX en la entrega",
      GPA_PIX: "PIX en la entrega",
      DIRECT_PAY_AT_DELIVERY: "Pague Directo durante el envío",
    },
  },
  LastOrders: {
    PREVIEW_TITLE: "Vista previa de los últimos pedidos",
    SHOW_FULL_TABLE: "Mostrar tabla completa",
    Error: {
      MESSAGE: "No se puede cargar la información de los últimos pedidos.",
      ACTION: "Intentar otra vez",
    },
    EmptyState: {
      MESSAGE:
        "No hay pedidos para mostrar. Una vez que esta POC realice un pedido, aparecerá aquí.",
    },
    Table: {
      CHANNEL: "Canal",
      ORDER_ID: "ID del pedido",
      STATUS: "Status",
      PAYMENT: "Pago",
      PLACED_ON: "Colocado en",
      PRODUCTS: "Productos",
      DELIVERY: "Entrega",
      TOTAL: "Total",
    },
    Status: {
      PARTIAL_DELIVERY: "Aceptado parcialmente",
      INVOICED: "Facturado",
      PENDING: "Pedido realizado",
      PENDING_PAYMENT: "Pago pendiente",
      PENDING_CANCELLATION: "Cancelación pendiente",
      PLACED: "Pedido realizado",
      MODIFIED: "Procesando con modificaciones",
      CANCELLED: "Cancelado",
      CONFIRMED: "Procesando",
      AVAILABLE: "Listo para recoger",
      DELIVERED: "Entregado",
      IN_TRANSIT: "Listo para la entrega",
      DENIED: "Envío rechazado",
    },
  },
  Notes: {
    TITLE: "Notes",
  },
};

export default es419;
