import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  UpdateButton: {
    TEXT: "Update",
  },
  AccountOverview: {
    TITLE: "POC overview",
  },
  ClubB: {
    TITLE: "Club B",
  },
  PhoneNumbers: {
    TITLE: "Phone numbers",
    CALLED_TAG: "Called",
    COPIED_TAG: "Copied",
    COPY_TOOLTIP: "Copy",
    COPIED_TOOLTIP: "Copied",
    NUMBER_RATE: {
      GOOD: "Good number",
      BAD: "Bad number",
      NEUTRAL: "Neutral number",
    },
    TOOLTIP: {
      INACTIVE: "Inactivate",
      NO_ANSWER: "No answer",
      SUCCESSFUL_CALL: "Successful call",
      CLICK_CALL: "Click to call",
      COPY_NUMBER: "Copy number",
      EDIT_NUMBER: "Edit number",
    },
    PHONE_TYPE: {
      INPUTTED_PHONE: "Added by agent",
      BEES_PHONE: "BEES phone",
      ERP_PHONE: "ERP phone",
    },
    BUTTON_ACTIVATE: "Activate",
    BUTTON_ADD_PHONE: "Add new phone",
    Modal: {
      DEACTIVATE_TITLE: "Deactivate phone number?",
      ACTIVATE_TITLE: "Activate phone number?",
      Button: {
        ACTIVATE: "Activate",
        DEACTIVATE: "Deactivate",
        CANCEL: "Cancel",
      },
      ModalMessage: {
        ACTIVATE_NUMBER: "You will be able to make calls to the number",
        INACTIVATE_NUMBER: "You won't be able to make calls to the number",
        INACTIVATE_NOTE: "if it is inactive.",
      },
    },
    AddEditPhoneModal: {
      ADD_TITLE: "Add new phone",
      EDIT_TITLE: "Edit phone",
      FIELD_PHONE_NUMBER: "Phone number",
      FIELD_RESPONSIBLE: "Responsible",
      SUB_TITLE: "Fill the form to add a new number.",
      PHONE_HINT_TEXT: "Include the country code, area code, and phone number.",
      NAME_HINT_TEXT: "Person answering this number.",
      REQUIRED_FIELD: "This field is required.",
      BUTTON_SAVE: "Save",
      BUTTON_CANCEL: "Cancel",
      CREATE_SUCCESS_MESSAGE: "The phone number successfully saved.",
      CREATE_ERROR_MESSAGE: "Unable to save the phone number.",
      EDIT_SUCCESS_MESSAGE: "The phone number has been edited.",
      EDIT_ERROR_MESSAGE: "Unable to edit the phone number.",
    },
    ToastMessage: {
      Success: {
        ACTIVATION: "The phone number ${} has been activated.",
        INACTIVATION: "The phone number ${} has been deactivated.",
        CLASSIFICATION: "The phone has been classified.",
      },
      Error: {
        ACTIVATION: "Unable to activate the phone number ${}.",
        INACTIVATION: "Unable to deactivate the phone number ${}.",
        CLASSIFICATION: "Unable to classify the phone number.",
      },
    },
    Error: {
      MESSAGE: "Unable to load POC's phone numbers.",
      ACTION: "Try again",
    },
  },
  Tasks: {
    TITLE: "Call tasks",
    UNABLE_TO_LOAD: "Unable to load the tasks information.",
    TRY_AGAIN: "Try again",
    EMPTY: "There is no tasks assigned to this POC.",
    GO_TO_TASKS: "Go to tasks",
    Global: {
      Title: {
        TOPLINE: "Topline task",
        MARKETPLACE: "Marketplace task",
      },
      Description: {
        TOPLINE: "Recommend products based on the suggested order.",
        MARKETPLACE: "Encourage the purchase of marketplace products.",
      },
    },
    Local: {
      Description: {
        DEFAULT: "Customized task created by the operations teams.",
      },
    },
  },
  PaymentMethod: {
    Methods: {
      CREDIT: "Credit",
      CASH: "Cash",
      BANK_SLIP: "Bank slip",
      BANK_SLIP_INSTALLMENT: "Bank slip installment",
      CHECK: "Check",
      CREDIT_CARD_POS: "Credit card on delivery",
      CREDIT_CARD_ONLINE: "Credit card online",
      PIX_AT_DELIVERY: "PIX during delivery",
      GPA_PIX: "PIX during delivery",
      DIRECT_PAY_AT_DELIVERY: "Direct pay at delivery",
    },
  },
  LastOrders: {
    PREVIEW_TITLE: "Last orders preview",
    SHOW_FULL_TABLE: "Show full table",
    Error: {
      MESSAGE: "Unable to load last orders information.",
      ACTION: "Try again",
    },
    EmptyState: {
      MESSAGE:
        "No orders to show. Once this POC places an order, it'll show up here.",
    },
    Table: {
      CHANNEL: "Channel",
      ORDER_ID: "Order ID",
      STATUS: "Status",
      PAYMENT: "Payment",
      PLACED_ON: "Placed on",
      PRODUCTS: "Products",
      DELIVERY: "Delivery",
      TOTAL: "Total",
    },
    Status: {
      PARTIAL_DELIVERY: "Partially delivered",
      INVOICED: "Billed",
      PENDING: "Order placed",
      PENDING_PAYMENT: "Payment pending",
      PENDING_CANCELLATION: "Cancellation pending",
      PLACED: "Order placed",
      MODIFIED: "Processing with modifications",
      CANCELLED: "Canceled",
      CONFIRMED: "Processing",
      AVAILABLE: "Ready for pickup",
      DELIVERED: "Delivered",
      IN_TRANSIT: "Out for delivery",
      DENIED: "Declined",
    },
  },
  Notes: {
    TITLE: "Notes",
  },
};

export default enUS;
