import { useIntl } from "react-intl";

import { Divider } from "@hexa-ui/components";

import PhoneActionModal from "@/components/phoneNumbers/components/phoneActionModal/PhoneActionModal";

import ClubB from "../../components/clubB/ClubB";
import LastOrdersPreview from "../../components/lastOrdersPreview/LastOrdersPreview";
import Notes from "../../components/notes/Notes";
import PhoneNumbers from "../../components/phoneNumbers/PhoneNumbers";
import Tasks from "../../components/tasks/Tasks";
import * as Styled from "./AccountPage.styles";

export default function AccountPage() {
  const { formatMessage } = useIntl();

  return (
    <>
      <PhoneActionModal />
      <Styled.AccountContainer>
        <Styled.AccountTitle
          size="H3"
          data-testid={`${AccountPage.name}-title`}
        >
          {formatMessage({ id: "AccountOverview.TITLE" })}
        </Styled.AccountTitle>
        <Styled.CardContainer
          elevated="small"
          border="medium"
          data-testid={`${AccountPage.name}-container`}
        >
          <Styled.AsideColumn>
            <PhoneNumbers />
            <Divider decorative />
            <Notes />
          </Styled.AsideColumn>
          <Styled.ColumnDivider orientation="vertical" decorative />
          <Styled.MainColumn>
            <Tasks />
            <Divider decorative />
            <LastOrdersPreview />
            <Divider decorative />
            <ClubB />
          </Styled.MainColumn>
        </Styled.CardContainer>
      </Styled.AccountContainer>
    </>
  );
}
