import { useCallback, useEffect } from "react";

import {
  accountSharedService,
  lastOrdersSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

export function useLastOrdersPreview() {
  const lastOrdersService = lastOrdersSharedService();
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);
  const {
    lastOrders,
    done: isLastOrdersDone,
    error: isLastOrdersError,
    isLoading: isLastOrderLoading,
  } = useSharedUnit(lastOrdersService);

  const getLastOrders = useCallback(() => {
    lastOrdersService.getLastOrders({
      accountId,
      vendorId,
    });
  }, [accountId, lastOrdersService, vendorId]);

  useEffect(() => {
    getLastOrders();
  }, [getLastOrders]);

  const isLastOrdersEmpty =
    isLastOrdersDone && !isLastOrdersError && !lastOrders.length;

  return {
    isLastOrderLoading,
    isLastOrdersEmpty,
    isLastOrdersError,
    getLastOrders,
  };
}
